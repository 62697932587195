import React from "react"
import rtStyles from "../../styles/shared/richtext.module.css"
import { AutoLink } from "../shared/link"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Bold = ({ children }) => <strong className={ rtStyles.rtBold }>{ children }</strong>
const Italic = ({ children }) => <i className={ rtStyles.rtItalic }>{ children }</i>
const Paragraph = ({ children }) => <p className={ rtStyles.rtPara }>{ children }</p>
const Heading2 = ({ children }) => <h2 className={ rtStyles.rtH2 }>{ children }</h2>
const Heading3 = ({ children }) => <h3 className={ rtStyles.rtH3 }>{ children }</h3>
const Heading4 = ({ children }) => <h4 className={ rtStyles.rtH4 }>{ children }</h4>
const Heading5 = ({ children }) => <h5 className={ rtStyles.rtH5 }>{ children }</h5>
const Heading6 = ({ children }) => <h6 className={ rtStyles.rtH6 }>{ children }</h6>
const UnorderedList = ({ children }) => <ul className={ rtStyles.rtUl }>{ children }</ul>
const OrderedList = ({ children }) => <ol className={ rtStyles.rtOl }>{ children }</ol>
const ListItem = ({ children }) => <li className={ rtStyles.rtLi }>{ children }</li>
const Blockquote = ({ node }) => {

  const quote = node.content[0].content[0].value;
  const source = node.content[(node.content.length-1)].content[0].value;

  return (
    <figure className={ rtStyles.rtBlockquote }>
      <blockquote>{ quote }</blockquote>
      { 
        (node.content.length > 1) &&
        <footer className={ rtStyles.rtBlockquoteFooter }>{ source }</footer>
      }
    </figure>
  )
}
const EmbeddedAsset = ({ node }) => {
  
  const { description, file } = node.data.target.fields || {};

  if( file !== undefined ) {
    const mimeType = file['en-US'].contentType;
    const mimeGroup = mimeType.split('/')[0];

    switch (mimeGroup) {
      case 'image':
        return (
          <div className={ rtStyles.rtImage }>
            <img
              alt={ description ?  description['en-US'] : null }
              src={ file['en-US'].url }
            />
          </div>
        )
      default:
        return <span title="Unsupported asset type"></span>
    }
  } else {
    return <span title="Unsupported asset type"></span>
  }
  
}
const Code = ({ text }) => {
  
  if ( text.indexOf('youtube.com/embed') > -1 ) {
    return <div dangerouslySetInnerHTML={{ __html:text }} className={ rtStyles.rtVideo }></div>;
  }  

  if ( text.indexOf('vimeo.com/video') > -1 ) {
    return <div dangerouslySetInnerHTML={{ __html:text }} className={ rtStyles.rtVideo }></div>;
  }

  return <code className={ rtStyles.rtCode }>{ text }</code>;

}
const Hyperlink = ({ node, children }) => <AutoLink uri={ node.data.uri }>{ children }</AutoLink>


const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{ text }</Bold>,
    [MARKS.ITALIC]: text => <Italic>{ text }</Italic>,
    [MARKS.CODE]: text => <Code text={ text }/>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: ( node, children ) => <Paragraph>{ children }</Paragraph>,
    [BLOCKS.HEADING_2]: ( node, children ) => <Heading2>{ children }</Heading2>,
    [BLOCKS.HEADING_3]: ( node, children ) => <Heading3>{ children }</Heading3>,
    [BLOCKS.HEADING_4]: ( node, children ) => <Heading4>{ children }</Heading4>,
    [BLOCKS.HEADING_5]: ( node, children ) => <Heading5>{ children }</Heading5>,
    [BLOCKS.HEADING_6]: ( node, children ) => <Heading6>{ children }</Heading6>,
    [BLOCKS.UL_LIST]: ( node, children ) => <UnorderedList>{ children }</UnorderedList>,
    [BLOCKS.OL_LIST]: ( node, children ) => <OrderedList>{ children }</OrderedList>,
    [BLOCKS.LIST_ITEM]: ( node, children ) => <ListItem>{ children }</ListItem>,
    [BLOCKS.QUOTE]: ( node ) => <Blockquote node={ node }/>,
    [BLOCKS.EMBEDDED_ASSET]: ( node ) => <EmbeddedAsset node={ node }/>,
    [INLINES.HYPERLINK]: ( node, children ) => <Hyperlink node={ node }>{ children }</Hyperlink>,
  },
  renderText: text => {
    if( (text.indexOf('youtube.com/embed') === -1) && (text.indexOf('vimeo.com/video') === -1) ) {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    } else {
      return text;
    }
  },
}

const RichText = ({ content }) => {
    return (
        documentToReactComponents(content.json, options)
    )
}

export default RichText