import React from "react"
import cmptStyles from "../../styles/shared/cookies.module.css"
import { useStaticQuery, graphql } from "gatsby"
import useLocalStorage from "../../hooks/useLocalStorage"

const Cookies = () => {
    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    ...Cookies
                }
            }
        `
    )

    const cookies = data.dataJson.cookies;

    const [showCookieMessage, setShowCookieMessage] = useLocalStorage("showCookieMessage", true)
    const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorage("hasAcceptedCookies", true)

    const manageCookies = (hasAccepted) => {

        setShowCookieMessage(false);
        setHasAcceptedCookies(hasAccepted);

    }

    // if cookies declined, set the GA global property to restrict tracking
    if( hasAcceptedCookies === false ) {
        if ( typeof window !== `undefined` ) {
            window['ga-disable-UA-72754044-3'] = true;
        }
    }

    return (
        <>
        {
            showCookieMessage &&
            <section className={ cmptStyles.wrapper } id="cookies">
                <div className={ cmptStyles.container }>
                    <div className={ cmptStyles.inner }>
                    
                        <div className={ cmptStyles.text }>
                            <p className={ cmptStyles.message }>{ cookies.message }</p>
                            <ul className={ cmptStyles.links }>
                                <li>
                                    <button 
                                        className={ cmptStyles.accept }
                                        onClick={ () => manageCookies(true) }
                                    >
                                        { cookies.acceptLabel }
                                    </button>
                                </li>
                                <li>
                                    <button 
                                        className={ cmptStyles.decline }
                                        onClick={ () => manageCookies(false) }
                                    >
                                        { cookies.declineLabel }
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
        }
        </>
    )
}

export default Cookies

export const cookiesQuery = graphql`
    fragment Cookies on DataJson {
        cookies {
            acceptLabel
            declineLabel
            message
        }
    }
`