import React from "react";
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";

const Metadata = ({ 
    content: { 
        route,
        seoTitle,
        author,
        seoDescription, 
        seoImage
    },
    siteMetadata: {
        siteName,
        siteUrl
    },
    localMetadata: {
        copyrightYear,
        defaultAuthor,
        defaultLanguage,
        defaultLocale,
        defaultLocaleVariant,
        defaultOrganization,
        defaultTwitterAuthor,
        facebookAppId,
        seoTitleSuffix,
        twitterHandle
    },
    isAmp
}) => {

    /* General Configuration */
    const metaAuthor = ( author ) ? author.name : defaultAuthor;
    const metaCopyrightYear = copyrightYear;
    const metaDescription = seoDescription ? seoDescription.seoDescription : '';
    const metaFacebookAppId = facebookAppId;
    const metaImage = ( seoImage && seoImage.fixed ) ? seoImage.fixed.src : '';
    const metaImageAlt = ( seoImage ) ? seoImage.description : '';
    const metaLanguage = defaultLanguage;
    const metaLocalVariant = defaultLocaleVariant;
    const metaOrganization = defaultOrganization;
    const metaSiteName = siteName;
    const metaSiteUrl = siteUrl;
    const metaTitle = seoTitle + seoTitleSuffix;
    const metaTwitterCreator = defaultTwitterAuthor;
    const metaTwitterHandle = twitterHandle;
    const metaUrl = ( route && ( route !== '/' ) ) ? siteUrl + '/' + route : siteUrl;

    /* AMP Configuration */
    const metaAmpHtml = metaUrl.replace(metaSiteUrl, metaSiteUrl + '/amp');
    const metaCanonical = metaUrl;

    /* Structured Data Configuration */
    const sdSchemaOrg = {
        '@type': 'Organization',
        name: metaOrganization,
    }
    
    const sdSchema = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: metaUrl,
        headline: metaTitle,
        inLanguage: metaLanguage,
        mainEntityOfPage: metaUrl,
        description: metaDescription,
        name: metaTitle,
        author: metaAuthor,
        copyrightHolder: sdSchemaOrg,
        copyrightYear: metaCopyrightYear,
        creator: sdSchemaOrg,
        publisher: sdSchemaOrg,
        image: {
            '@type': 'ImageObject',
            url: metaImage,
        },
    }

    return (
        <Helmet>

            {
                ( isAmp === 'true' ) &&
                <html amp lang={ metaLanguage }/>
            }
            {
                ( isAmp === 'true' ) &&
                <script async src="https://cdn.ampproject.org/v0.js"></script>
            }
            {
                ( isAmp === 'true' ) &&
                <style amp-boilerplate>{`body{-webkit-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-moz-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-ms-animation:-amp-start 8s steps(1,end) 0s 1 normal both;animation:-amp-start 8s steps(1,end) 0s 1 normal both}@-webkit-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-moz-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-ms-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-o-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}`}</style>
            }
            {
                ( isAmp === 'true' ) &&
                <noscript>{`<style amp-boilerplate>body{-webkit-animation:none;-moz-animation:none;-ms-animation:none;animation:none}</style>`}</noscript>
            }
            {
                ( isAmp === 'false' ) &&
                <html lang={ metaLanguage }/>
            }
            {
                ( isAmp === 'falsey' ) &&
                <link rel="amphtml" href={ metaAmpHtml }/>
            }

            <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
            <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1,shrink-to-fit=no"/>

            <link rel="sitemap" type="application/xml" href="/sitemap.xml"/>
            <meta name="googlebot" content="all"/>
            <meta name="msnbot" content="all"/>
            <meta name="robots" content="all"/>
            <meta name="slurp" content="all"/>

            <title>{ metaTitle }</title>

            <link rel="canonical" href={ metaCanonical }/>

            <meta name="author" content={ metaAuthor }/>
            <meta name="description" content={ metaDescription }/>
            <meta name="image" content={ metaImage }/>

            <meta itemprop="description" content={ metaDescription }/>
            <meta itemprop="image" content={ metaImage }/>

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000"/>
            <meta name="apple-mobile-web-app-title" content="XPT&reg;"/>
            <meta name="application-name" content="XPT&reg;"/>
            <meta name="msapplication-TileColor" content="#2d89ef"/>
            <meta name="theme-color" content="#ffffff"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:creator" content={ metaTwitterCreator }/>
            <meta name="twitter:description" content={ metaDescription }/>
            <meta name="twitter:site" content={ metaTwitterHandle }/>
            <meta name="twitter:title" content={ metaTitle }/>
            <meta name="twitter:image" content={ metaImage }/>
            <meta name="twitter:image:secure_url" content={ metaImage }/>
            <meta name="twitter:image:alt" content={ metaImageAlt }/>

            <meta property="og:description" content={ metaDescription }/>
            <meta property="og:image" content={ metaImage }/>
            <meta property="og:image:secure_url" content={ metaImage }/>
            <meta property="og:image:alt" content={ metaImageAlt }/>
            <meta property="og:locale" content={ metaLocalVariant }/>
            <meta property="og:site_name" content={ metaSiteName }/>
            <meta property="og:title" content={ metaTitle }/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={ metaUrl }/>
            <meta name="fb:app_id" content={ metaFacebookAppId }/>

            <script type="application/ld+json">{ JSON.stringify(sdSchema) }</script>
            <script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=MsesVw"></script>
        </Helmet>
    )
}

export default Metadata

export const envSiteMetadata = graphql`
    fragment EnvSiteMetadata on Query {
        site {
            siteMetadata {
                siteName
                siteUrl
            }
        }
    }
`

export const localMetadataQuery = graphql`
    fragment LocalMetadata on DataJson {
        localMetadata {
            copyrightYear
            defaultAuthor
            defaultLanguage
            defaultLocale
            defaultLocaleVariant
            defaultOrganization
            defaultTwitterAuthor
            facebookAppId
            seoTitleSuffix
            twitterHandle
        }
    }
`